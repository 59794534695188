import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { Container, Row, Col } from "react-bootstrap"
import "../components/fragments"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Pager from "../components/pager"
import EventsNav from "../components/eventsNav"
import CardCollectionNode from "../components/cardCollectionNode"
import Facet from "../components/facet"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import { getCrumbsObj } from "../utils/ug-utils"
import BannerAd, { BANNERAD_CHILDREN_EVENT } from "../components/bannerAd"

import "../styles/event.scss"

const EventTypePage = ({ data, pageContext }) => {
  const { id, categoryId, name } = pageContext
  const eventsData = data.events.edges
  const webinarCategoriesPast = data.webinarCategoriesPast.edges.map((edge) => edge.node)
  const crumbsObj = getCrumbsObj(pageContext)

  // Add an "All" option to the webinar categories.
  webinarCategoriesPast.push({ id: "all", name: "All Categories", url: "/events/type/webinar/dates/past" })

  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class: "event-list-page",
          "aria-label": "Webinars Page"
        }}
      />
      <Seo title={`Events - ${name}`} keywords={[`gatsby`, `application`, `react`]} />

      {/**** Header and Title ****/}
      <div id="rotator" className="shorter-hero">
        <div className="container ft-container">
          <h1 className="fancy-title">Events</h1>
        </div>
      </div>

      {/**** Body content ****/}
      <div className="page-container">
        <Container>
          {crumbsObj.crumbs && <Breadcrumb {...crumbsObj} aria-label="Webinars" />}
          <Row className="row-with-vspace site-content">
            <EventsNav selected={`type/${id}/past`} />
          </Row>
          <Row className="row-with-vspace site-content">
            <div className="facets">
              <Facet
                data={webinarCategoriesPast}
                urlPrefix="/events/type/webinar/dates/past/category"
                useHashtag={false}
                className=""
                linkClassName="btn btn-outline-info"
                linkSelectedClassName="btn btn-info"
                selected={categoryId || 'all'}
              />
            </div>
          </Row>
          <Row className="row-with-vspace site-content">
            <Col md={12} className="content-area">
              {/**** Body content ****/}
              <Container>
                <Col md={12} className="content-area">
                  {/**** Body content ****/}
                  <CardCollectionNode
                    displayMode="grid"
                    cardStyle="aad-youtube-style"
                    numColumns={4}
                    nodes={eventsData}
                  />
                </Col>
              </Container>
              <Pager {...pageContext} />
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default EventTypePage

export const query = graphql`
  query ($id: String, $categoryId: String, $currentDate: Date!, $skip: Int!, $limit: Int!) {
    events: allNodeEvent(
      limit: $limit
      skip: $skip
      filter: {
        field_event_type: { eq: $id }
        field_date: { end_value: { lt: $currentDate } }
        relationships: { field_webinar_category: { elemMatch: { id: { eq: $categoryId } } } }
      }
      sort: { fields: field_date___value, order: DESC }
    ) {
      edges {
        node {
          ...Webinar
        }
      }
    }
    webinarCategories: allTaxonomyTermWebinarCategories(
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          ...TaxonomyTerm
        }
      }
    }
    webinarCategoriesPast: allTaxonomyTermWebinarCategories(
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          ...TaxonomyTerm
        }
      }
    }
  }
`
